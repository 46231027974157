import Header from "../layout/Header";

import Hero from "../layout/Hero";
import AboutUs from "../layout/AboutUs";
import Services from "../layout/Services";
import Portfolio from "../layout/Portfolio";
import Clients from "../layout/Clients";

import Footer from "../layout/Footer";

import Helmet from "../components/Helmet";

function Home() {
  return (
    <div className="Home">
      <Helmet pageTitle="Home" />

      <Header />
      <Hero />

      <main id="main">
                {/*
        <AboutUs />

        <Services />
        <Portfolio />
        <Clients />
  */}
      </main>

      <Footer />
    </div>
  );
}

export default Home;
