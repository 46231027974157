import PrivacyPolicy from "../components/PrivacyPolicy";

function Footer() {
    return (
<>
  <footer id="footer">

    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-3 footer-contact">
            <h4>noze S.r.l.</h4>
            <p>
              Via Giuntini, 25 <br/>
              56023 Navacchio (PI)<br/>
              Italy <br/><br/>
              <strong>Phone:</strong> +39 050 7916874<br/>
              <strong>Email:</strong> info@noze.it<br/>
              <strong>PEC:</strong> info@pec.noze.it<br/>
              <strong>P. IVA: </strong> 01547110500<br/>

              <strong>Codice SDI:</strong> M5UXCR1<br/>
            </p>
          </div>
          <div className="col-lg-3 col-md-3 footer-contact">
            </div>
          <div className="col-lg-3 col-md-3 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/about">About</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/contacts">Contacts</a></li>

              {/*
              <li><i className="bx bx-chevron-right"></i> <a href="/services">Services</a></li>

              <li><i className="bx bx-chevron-right"></i> <a href="/privacy">Privacy policy</a></li>
                  */}
            </ul>
          </div>

          <div className="col-lg-3 col-md-3 footer-links">
            <h4>Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="/contacts">Cloud/Web Apps</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/contacts">Cyber Security</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/contacts">e-Health</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/contacts">Innovation Management</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/contacts">Research & Development</a></li>
            </ul>
          </div>
{/*
          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Join Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action="" method="post">
              <input type="email" name="email" />
              <input type="submit" value="Subscribe" />
            </form>
          </div>
                */}
        </div>
      </div>
    </div>

    <div className="container d-md-flex py-2">

      <div className="me-md-auto text-center text-md-start py-2">
        <div className="copyright">
          &copy; 2000 - {new Date().getFullYear()} <strong><span>noze S.r.l.</span></strong>
        </div>
        <div className="credits">
        
        </div>
      </div>
      <div style={{marginRight: 20, marginTop: 10}}><PrivacyPolicy/></div>
      <div className="social-links text-center text-md-right pt-3 pt-md-0">
        <a href="https://www.facebook.com/noze.it" target="_blank" rel="noreferrer" className="facebook"><i className="bx bxl-facebook"></i></a>
        <a href="https://linkedin.com/company/noze-s.r.l." target="_blank" rel="noreferrer" className="linkedin"><i className="bx bxl-linkedin"></i></a>
      </div>
    </div>
  </footer>

  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
</>
  );

}

export default Footer;