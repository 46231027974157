import {  useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();
    return (

  <header id="header" className="fixed-top">
    <div className="container d-flex align-items-center">

      {/*<!--<h1 className="logo me-auto"><a href="index.html"><span>Com</span>pany</a></h1>-->*/}
      {/*<!-- Uncomment below if you prefer to use an image logo -->*/}
      <a href="/" className="logo me-auto"><img src="assets/img/logo/noze.jpg" alt="" className="img-fluid" /></a>

      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          <li><a href="/" className={location.pathname === '/' ? 'active':''}>Home</a></li>
          <li><a href="/about" className={location.pathname === '/about' ? 'active':''}>About</a></li>

 {/*
          <li className="dropdown"><a href="#"><span>About</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="about">About Us</a></li>
              <li><a href="team.html">Team</a></li>
              <li><a href="testimonials.html">Testimonials</a></li>
            </ul>
          </li>

         <li><a href="services.html">Services</a></li>
          <li><a href="portfolio.html">Portfolio</a></li>
          <li><a href="pricing.html">Pricing</a></li>
          <li><a href="blog.html">Blog</a></li>
    */}
          <li><a href="/contacts" className={location.pathname === '/contacts' ? 'active':''}>Contact</a></li>
          <li><a href="https://shop.noze.it" target="_blank">Shop</a></li>

        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>{/*<!-- .navbar -->*/}

      <div className="header-social-links d-flex">  
        <a href="https://www.facebook.com/noze.it" target="_blank" rel="noreferrer" className="facebook"><i className="bu bi-facebook"></i></a>
        <a href="https://linkedin.com/company/noze-s.r.l." target="_blank" rel="noreferrer" className="linkedin"><i className="bu bi-linkedin"></i></a>
      </div>
    </div>
    
  </header>

  );

}

export default Header;