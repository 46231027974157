import {  useLocation } from 'react-router-dom';


function Breadcrumbs() {
  const location = useLocation();
    return (

<section id="breadcrumbs" className="breadcrumbs">
<div className="container">

  <div className="d-flex justify-content-between align-items-center">
    <ol>
      <li><a href="/">Home</a></li>
      <li><b>{location.pathname.substring(1)}</b></li>
    </ol>
  </div>

</div>
</section>


);
}


export default Breadcrumbs;