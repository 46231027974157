import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import './scss/style.scss';

import Home from './pages/Home';
import Contacts from './pages/Contacts';
import About from './pages/About';
import Error404 from './pages/Error404';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-N6PWKFD'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Home/>}/>
        <Route path={`${process.env.PUBLIC_URL}/about`} element={<About/>}/>
        <Route path={`${process.env.PUBLIC_URL}/contacts`} element={<Contacts/>}/>
        
        <Route path={`${process.env.PUBLIC_URL}/notfound`} element={<Error404/>}/>
        <Route path='*' element={<Navigate to={`${process.env.PUBLIC_URL}/notfound`} replace />}/>

      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
