
const Slides = [
  {
      images: 'cloud-web-apps.jpg',
      category: 'Cloud / Web Apps',
      title: 'Cloud-native Architectures for Scalable Web/Mobile Applications',
      link: '/cloud-web-apps'
  },
  {
      images: 'cyber_security.jpg',
      category: 'Cyber Security',
      title: 'Analyze and Secure your IT Infrastructures',
      link: '/cyber_security'
  },
  {
      images: 'e_health.jpg',
      category: 'e-Health',
      title: 'Data-driven Software for Biomedical Research',
      link: '/e-health'
  },
  {
      images: 'innovation_management.jpg',
      category: 'Innovation Management',
      title: 'Introducing Technologies in your Workflows',
      link: 'innovation-management'
  },
  {
    images: 'quantum.jpg',
    category: 'Research & Development',
    title: 'Quantum Computing, Crypto & NFT, Artificial Intelligence',
    link: 'research-development'
},
]


function Hero() {
    return (

<section id="hero">
    <div id="heroCarousel" data-bs-interval="4000" className="carousel slide carousel-fade" data-bs-ride="carousel">

      <div className="carousel-inner" role="listbox">

        {Slides.map((value , i ) => (
        <div className={ i===0 ? 'carousel-item active' : 'carousel-item'} style={{backgroundImage: `url(assets/img/slide/${value.images}`}} key={i}>
          <div className="carousel-container">
            <div className="carousel-content animate__animated animate__fadeIn">
              <h2>{value.category}</h2>
              <p>{value.title}</p>
              
              <div className="text-center"><a href="/contacts" className="btn-get-started">Get Started</a></div>
              
            </div>
          </div>
        </div>
        ))}

      </div>

      <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
        <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
      </a>

      <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
        <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
      </a>

      <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

    </div>
  </section>

  );

}

export default Hero;