import React, { Component } from "react";

class PrivacyPolicy extends Component{
    render(){
        return(
            <React.Fragment>
                <a href="https://www.iubenda.com/privacy-policy/67178355" rel="noreferrer" target="_blank" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe" title="Privacy Policy" style={{color: 'white'}}>Privacy Policy</a>    
            </React.Fragment>
        )
    }
}


export default PrivacyPolicy;
