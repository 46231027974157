const ClientsList = [
  {
    client: 'Scuola Superiore Sant\'Anna',
    images: 'sssa.png',
  },
  {
    client: 'Scuola Normale Superiore',
    images: 'sns.jpg',
  },
  {
    client: '01S Community Company',
    images: '01s.png',
  },
  {
    client: 'Client 4',
    images: 'client-4.png',
  },
  {
    client: 'Client 5',
    images: 'client-5.png',
  },
  {
    client: 'Client 6',
    images: 'client-6.png',
  },
  {
    client: 'Client 7',
    images: 'client-7.png',
  },
  {
    client: 'Client 8',
    images: 'client-8.png',
  },

]


function Clients() {
    return (

<section id="clients" className="clients">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Clients</h2>
        </div>

        <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up">

        {ClientsList.map((value , i ) => (

          <div className="col-lg-3 col-md-4 col-6">
            <div className="client-logo">
              <img src={`assets/img/clients/${value.images}`} className="img-fluid" alt={value.client} />
            </div>
          </div>
        ))}

        </div>

      </div>
    </section>

    );

}

export default Clients;