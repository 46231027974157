import Header from '../layout/Header';
import Breadcrumbs from '../layout/Breadcrumbs';
import Footer from '../layout/Footer';

function Error404() {
  return (

    <div className="Error404">
        <Header />

        <main id="main">
            <Breadcrumbs />
            <section id="error" className="error">
              <div className="container text-center d-flex align-items-center justify-content-center" style={{minHeight: '30vh'}}>
                <div className="row" data-aos="fade-up">
                  <h2>Page not found</h2>
                </div>
              </div>
            </section>
        </main>

        <Footer />

    </div>
  );
}

export default Error404;