/* eslint-disable jsx-a11y/iframe-has-title */
import Header from "../layout/Header";
import Breadcrumbs from "../layout/Breadcrumbs";
import Footer from "../layout/Footer";

import Helmet from "../components/Helmet";

function Contacts() {
  return (
    <div className="Contacts">
      <Helmet pageTitle="Contacts" />

      <Header />

      <main id="main">
        <Breadcrumbs />
        <div className="map-section">
          <iframe
            style={{ border: 0, width: "100%", height: "350px" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d745.7221674561777!2d10.487525685262584!3d43.68507825073864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfd7813e8c9ed6d88!2snoze%20S.r.l.!5e1!3m2!1sit!2sit!4v1627901517269!5m2!1sit!2sit"
            frameBorder="0"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        <section id="contact" className="contact">
          <div className="container">
            <div className="row justify-content-center" data-aos="fade-up">
              <div className="col-lg-10">
                <div className="info-wrap">
                  <div className="row">
                    <div className="col-lg-4 info">
                      <i className="bi bi-geo-alt"></i>
                      <h4>Office:</h4>
                      <p>
                        c/o Polo Tecnologico di Navacchio
                        <br />
                        Via Giuntini, 25
                        <br />
                        56023 Navacchio (PI)
                        <br />
                        Italy
                      </p>
                    </div>

                    <div className="col-lg-4 info mt-4 mt-lg-0">
                      <i className="bi bi-envelope"></i>
                      <h4>Email:</h4>
                      <p>info@noze.it</p>
<br/>
                      <i className="bi bi-envelope"></i>
                      <h4>PEC:</h4>
                      <p>info@pec.noze.it</p>
                    </div>

                    <div className="col-lg-4 info mt-4 mt-lg-0">
                      <i className="bi bi-phone"></i>
                      <h4>Phone:</h4>
                      <p>+39 050 7916874</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5 justify-content-center" data-aos="fade-up">
              <div className="col-lg-10">
                <form
                  action="https://hosting.noze.it/forms/contact.php"
                  method="post"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Contact Us</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default Contacts;
