/* eslint-disable jsx-a11y/iframe-has-title */
import Header from "../layout/Header";
import Breadcrumbs from "../layout/Breadcrumbs";
import Footer from "../layout/Footer";

import Helmet from "../components/Helmet";

function About() {
  return (
    <div className="About">
      <Helmet pageTitle="About" />

      <Header />

      <main id="main">
        <Breadcrumbs />
 
        <section id="about-us" className="about-us">
      <div className="container" data-aos="fade-up">

        <div className="row content">
          <div className="col-lg-6" data-aos="fade-right">
            <h2>noze</h2>
            <h3>Innovation Agency</h3>
            <p>
            noze was founded in 2000 and is focused on Open Technologies, Cloud Architectures, Web Apps, Cyber Security, e-Health, Innovation Management, R&D. </p>
            <p>It's based at the Technological Center of Navacchio (Pisa, Italy) and actively collaborates with local, national and foreign companies and startups. </p>
            <p>Collaborations with the Pisa research ecosystem are ongoing (Università degli Studi di Pisa, Scuola Normale Superiore di Pisa, Scuola Superiore Sant’Anna di Pisa, CNR) and with other Italian universities, other CNR institutes and Italian and European research centers.

            </p>
            <p>
            Always focused on Open worlds, noze was among the founding members of CIRS, the first Italian consortium of FLOSS (Free Libre Open Source Software) companies, and of T-OSSLab, the Tuscan regional competence center on open source software, as well as active partner of Italian and European associations. noze contributed to the birth of TeCNA, the IT companies association of CNA Pisa and CNA Regionale Toscana, now merged into CNA Informatica.
            </p>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
            <br/>
            <p>noze has profitable collaborations with important private clients (Radio 105, Radio Monte Carlo, Giunti Editore, Giunti Scuola, Istituto De Angeli Gruppo Fareva, Sabre Italia, Bassilichi, KSolutions, TDGroup, SIRM Società Italiana di Radiologia Medica, FASI Federazione Arrampicata Sportiva Italiana, Navicelli Spa, Witech, Sourcesense and others) and public entities (Università degli Studi di Pisa, Scuola Superiore Sant'Anna di Pisa, Scuola Normale Superiore, Università di Oxford, Segretariato Generale dell'ONU di Ginevra, ILO - International Labour Organization di Ginevra, Regione Toscana, Provincia di Teramo, Provincia di Vicenza, Provincia di Pisa, Provincia di Carbonia-Iglesias, Comune di Ofena, Comune di Cerreto Guidi, Comune di San Giuliano Terme, Comune di Sesto Fiorentino, Comune di Scandicci, Comune di Porcari and others).
              </p>
            <p>The main business lines are:</p>
            <ul>
              <li><i className="ri-check-double-line"></i> Cloud / Web Apps</li>
              <li><i className="ri-check-double-line"></i> Cyber Security</li>
              <li><i className="ri-check-double-line"></i> e-Health</li>
              <li><i className="ri-check-double-line"></i> Innovation Management</li>
              <li><i className="ri-check-double-line"></i> Research & Development</li>

            </ul>
            {/*
            <p className="fst-italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua.
            </p>
  */}
          </div>
        </div>

      </div>
    </section>
      </main>

      <Footer />
    </div>
  );
}

export default About;
