import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} | noze - Innovation Agency</title>
                    {/*<meta name="description" content={`${this.props.pageTitle}, noze - Innovation Agency`} />*/}
                    <meta name="description" content="Cloud-native Architectures for Scalable Web/Mobile Applications, Web Apps, Cyber Security, e-Health, Data-driven Software for Biomedical Research, Innovation Management, Quantum Computing, Crypto & NFT, Artificial Intelligence, Research & Development, Open Source Software, Stefano Noferi, noze - Innovation Agency" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
